<div class="d-flex justify-content-center loading" *ngIf="spinner">
  <div class="spinner-border" role="status" >
    <span class="sr-only loading_spinner"></span>
  </div>
</div>

<div id="contact" class="col-md-4 col-sm-12 mx-auto">
    <div class="contact-us">
      <div class="py-4 text-center">
        <div class="contact-us-title ">Please provide us with a few details so that we can connect with you.</div>
        <div class="text-center"><b>OR</b></div>
        You can contact us on <a href="tel:+91-8928382838">+91-8928382838</a>, between 10AM to 10PM or <br>EMAIL US at <a href="mailto:admin@primeacademypune.com">admin@primeacademypune.com</a>
      </div>
      <form>
        <div class="form-group">
          <input
            class="w-100 p-input"
            type="text"
            placeholder="Your Name"
            #name
          />
        </div>
        <div class="form-group">
          <input
            class="w-100 p-input"
            type="text"
            placeholder="Your Email ID"
            #email
          />
        </div>
        <div class="form-group">
          <input
            class="w-100 p-input"
            type="text"
            placeholder="Your Mobile Number"
            #mob
          />
        </div>
        <div class="form-group">
          <input
            class="w-100 p-input"
            type="text"
            placeholder="Your/Your Child's School Name"
            #schoolname
          />
        </div>
        <div class="form-group">
          <input
            class="w-100 p-input"
            type="text"
            placeholder="Your/Your Child's year of 10th board exam"
            #boardexamyear
          />
        </div>
        <div class="form-group">
          <!-- <input
            class="w-100 p-input"
            type="text"
            placeholder="Want o Prepare for"
          /> -->
          <select class="w-100 p-input" #preparefor>
            <option selected>Want to Prepare for?</option>
            <option value="Engineering">Engineering</option>
            <option value="Medical">Medical</option>
          </select>
        </div>

        <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="Brief Address"
              #briefaddress
            />
        </div>

        
        <div class="form-group">
            <!-- <input
              class="w-100 p-input"
              type="text"
              placeholder="Want o Prepare for"
            /> -->
            <select class="w-100 p-input" #state>
              <option selected>State/UT</option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
              <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadra and Nagar Haveli and Daman & Diu">Dadra and Nagar Haveli and Daman & Diu</option>
              <option value="The Government of NCT of Delhi">The Government of NCT of Delhi</option>
              <option value="Jammu & Kashmir">Jammu & Kashmir</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">Puducherry</option>

            </select>
          </div>

        <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="City"
              #city
            />
        </div>
        <div class="form-group">
          <textarea
            class="w-100 p-input"
            type="text"
            placeholder="Your Message for us.."
            #message
          ></textarea>
        </div>
        <button class="p-btn-red w-100 mb-5" (click)="send_form()">SUBMIT DETAILS</button>
      </form>
    </div>
  </div>