import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2, HostListener, ViewChild } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @ViewChild('message') message: any;
  @ViewChild('mob') mob: any;
  @ViewChild('name') name: any;
  spinner=false;

  constructor(private renderer: Renderer2, private http: HttpClient, 
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar) {}
  innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  isShow: boolean;
  ngOnInit(): void {
    this.isShow = true;
  }
  goToHeader() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  send_form(){
    console.log(this.name.nativeElement.value, this.message.nativeElement.value, this.mob.nativeElement.value)
    // this.spinner = true;
    this.router.navigateByUrl('/');
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.http.post(
      environment.url + '/forms/footer_submit_details', 
      {
        name: this.name.nativeElement.value, 
        mob: this.mob.nativeElement.value,
        message: this.message.nativeElement.value, 
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log('Caught the error', this.snackBar);
          let snackBarRef = this.snackBar.open('There was some error when accepting your form. Please contact +91-8928382838 for assistance.', 'Home Page', {duration: 10000});
          snackBarRef.onAction().subscribe(() => {
            this.router.navigateByUrl('/');
          })
          // this.router.navigateByUrl('/');
          // this.spinner = false;
        // Return an observable with a user-facing error message.
          return throwError(() => new Error('We are facing some error; please try again later.'));
        })
      ).subscribe(result => {
        console.log(result)
        let snackBarRef = this.snackBar.open('We have received your enquiry. We will try to reach you shortly', 'Home Page', {duration: 4000});
        snackBarRef.onAction().subscribe(() => {
          this.router.navigateByUrl('/');
        })
        // this.spinner = false;
      })
  }
}
