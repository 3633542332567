<!-- Footer -->
<div class="container-fluid text-center pt-5 branches">
  <div class="title-white-40">OUR BRANCHES</div>
  <div class="row my-5">
    <div class="col">
      <a
        href="https://maps.app.goo.gl/2VyGPxxW9QCDpH43A" target="_blank"
        >FC ROAD (Head Office)
      </a>
      <p>
        Prime Academy, 2nd floor, Dinkar Vertex, next to Starbucks/ FC gate-2 , FC Road, Pune, Maharashtra- 411004</p>
      <p>8928382838 / 9021850095</p>
    </div>
    <div [class.w-100]="innerWidth < 768"></div>
    <div class="col" id="p2">
      <a
        href="https://goo.gl/maps/maj4V6ppCp88MUx79" target="_blank"
        >VIMAN NAGAR</a
      >
      <p>Off No. – 307, Tradenet Building, Above Maruti Showroom, Vimannagar</p>
      <p>9595520089</p>
    </div>
    <div class="col" id="p3">
      <a
        href="https://goo.gl/maps/jiu4opSsoKqC4GPbA" target="_blank"
        >PIMPRI</a
      >
      <p>Off No. – 5, 3rd Floor, Satyam Apartment, Adjacent to Pimpri Station </p>
      <p>8055340090</p>
    </div>
    <div [class.w-100]="innerWidth < 768"></div>
    <div class="col" id="p4">
      <a
        href="https://maps.app.goo.gl/c4xq9Zvnw7PSyi36A" target="_blank"
        >HADAPSAR</a
      >
      <p>Prime Academy, Office 202, Vishal Ventila, Behind Nobel hospital, Hadapsar Pune 411013</p>
      <p>9595040094</p>
    </div>
    <div class="col" id="p5">
      <a
        href="https://goo.gl/maps/ejQbii16asR6ccFU7" target="_blank"
        >NIBM</a
      >
      <p>Building No. C-3, Office No.301, Brahma Majestic, NIBM Road, Kondhwa, Pune- 411048</p>
      <p>020–4130 7590 / 9371663143</p>
    </div>
  </div>
</div>
<footer class="blue-div page-footer font-small indigo" style="padding-bottom: 78px">
  <div></div>
  <!-- Footer Links -->
  <div class="container">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-4 col-sm-12">
        <ul class="list-unstyled">
          <li class="col-lg-12 col-sm-12 col-xs-12">
            <img
              class="footer-logo"
              src="./../../assets/footer/footer-logo-img.png"
              alt="Prime Academy"
            />
          </li>
          <!-- <li class="col-lg-12 col-sm-12 col-xs-12">
            <p class="my-4 pb-5 pr-4 small text-opacity">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Doloremque id, nisi corrupti quis iste iusto deserunt dicta dolor,
              consectetur Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Corrupti, tenetur!
            </p>
          </li> -->
          <li class="footer-contact col-sm-6 col-xs-6 pt-4">
            <div>CONTACT US</div>
            <address>
              <p class="my-1 pb-4 small text-opacity">
                10AM to 10PM<br />
                <a href="tel:8928382838">8928382838</a>
              </p>
            </address>
          </li>
          <li class="footer-email col-sm-6 col-xs-6">
            <div>EMAIL US</div>
            <address>
              <p class="my-1 pb-4 small text-opacity">
                <a href="mailto:admin@primeacademypune.com">admin@primeacademypune.com </a>
              </p>
            </address>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none first" />

      <!-- Grid column -->
      <div class="footer-email colsep col-md-2 col-sm-6 col-xs-6 pt-5">
        <ul class="menu1 list-unstyled text-md-left">
          <li>
            <a href="javascript:void(0)" routerLink="/about">ABOUT US</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/courses/0">PROGRAMS</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/results">RESULTS</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/testimonials">TESTIMONIALS</a>
          </li>
          <li>
            <a href="https://forms.gle/Vsk1ksJq8a5yWH2U7" target="_blank">Jobs@Prime</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <!-- <hr class="clearfix w-100 d-md-none" /> -->

      <!-- Grid column -->
      <div class="colsep col-md-2 col-sm-6 col-xs-6 text-md-left pt-5">
        <ul class="menu2 list-unstyled">
          <li>
            <a href="javascript:void(0)" routerLink="/faculty">FACULTY</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/faqs">FAQs</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/blogs">BLOGS</a>
          </li>
          <li>
            <a href="javascript:void(0)" routerLink="/testimonials">MEET THE ALUMNI</a>
          </li>
        </ul>
      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none" />

      <!-- Grid column -->
      <div id="contact" class="col-md-4 col-sm-12 mx-auto">
        <div class="contact-us">
          <div class="contact-us-title">Want to learn more? Let’s connect</div>
          <form>
            <div class="form-group">
              <input
                class="w-100 p-input"
                type="text"
                placeholder="Your Name"
                #name
              />
            </div>
            <div class="form-group">
              <input
                class="w-100 p-input"
                type="text"
                placeholder="Your Mobile Number"
                #mob
              />
            </div>
            <div class="form-group">
              <textarea
                class="w-100 p-input"
                type="text"
                placeholder="Your Message for us.."
                #message
              ></textarea>
            </div>
            <button class="p-btn-red w-100" (click)="send_form()">SUBMIT DETAILS</button>
          </form>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12 goto-top">
        <button
          class="float-right bg-transparent"
          (click)="goToHeader()"
        ></button>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12 py-1 text-opacity mx-3">
        <p style="font-size: 10px" class="text-white text-center">
          Copyright © 2020 Prime Academy Pune. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>

  <!-- Copyright -->
</footer>
<!-- Footer -->

<!-- <div class="blue-div mt-5 pt-5 text-center">

    <div class="container pt-4 mt-5" style="font-size: 14px; text-align: center">
        <div class="row text-light">

            <div class="col-md-4 mb-2">
                <p>
                    <a href="/assets/download/brochure.pdf" style="font-size: 32px; color:white">Information
                        Brochure</a>
                </p>
                <a href="/assets/download/brochure.pdf">
                    <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains" class="mt-4"
                        src="/assets/footer/brochure.png" style="width: 70%">
                </a>
            </div>

            <div class="col-md-4 mb-2">
                <p>
                    <a href="/FunAtPrime" style="font-size: 32px; color:white;">Fun at Prime</a>
                </p>
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="5" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="6" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="7" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="8" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="9" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="10" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="11" class="active"></li>
                    </ol>

                    <div class="carousel-inner">
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/B.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/C.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/D.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/F.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/G.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/H.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/I.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/J_1.jpg">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/K.JPG">
                        </div>
                        <div class="carousel-item">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/L.jpg">
                        </div>
                        <div class="carousel-item active carousel-item-left">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/M.jpg">
                        </div>
                        <div class="carousel-item carousel-item-next carousel-item-left">
                            <img alt="Prime Academy, Best Coaching Institute in Pune, JEE Advance, JEE mains"
                                class="d-block w-100" src="/assets/footer/footer_fun_small/N.jpg">
                        </div>
                    </div>

                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>

                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>

                </div>
            </div>

            <div class="col-md-4 mb-2">
                <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSftcmnKUJMhlROPu5XmyteFZCSsSOSOGWtRoeX_UM57Ace5Ow/viewform?usp=sf_link"
                        style="font-size: 32px; color:white;">Get in touch</a></p>

                <p><i class="fa fa-calendar mb-2" style="font-size:32px;">
                        <span id="contact" class="mb-4"><a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSftcmnKUJMhlROPu5XmyteFZCSsSOSOGWtRoeX_UM57Ace5Ow/viewform?usp=sf_link"
                                style="font-size: 20px; color:white;">Get Appointment</a></span>
                    </i></p>

                <p><i class="fa fa-suitcase mb-2" style="font-size:32px;">
                        <span id="contact" class="mb-4"><a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSe7DY_VxY4hldXilQMB_0cYbOjnI6EkO2_X9Bo9S1MCap-yUg/viewform?usp=sf_link"
                                style="font-size: 20px; color:white;">Jobs@ Prime</a></span>
                    </i></p>

                <p><i class="fa fa-graduation-cap" style="font-size:32px;">
                        <span id="contact"><a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSeET9C9-mBOyWA5FZuMKoPVMY4GHLRMrbIcNn71UGM0E0RUTQ/viewform?usp=sf_link"
                                style="font-size: 20px; color:white;">Alumni</a></span>
                    </i></p>
                <p>
                    <a target="_blank" href="mailto:admin@primeacademypune.com"
                        style="font-size: 32px; margin-right:2vw; color:white;"><i class="fa fa-envelope"></i></a>
                    <a target="_blank" href="https://www.facebook.com/primetutorials/"
                        style="font-size: 32px; color:white;">
                        <i class="fa fa-facebook"></i></a>
                </p>
            </div>

        </div>

    </div>

    <hr width="100%" style="border-color: white;">
    <p class="text-center mt-4" style="color: white; font-size: 15px">Copyright © Prime Academy Pune. All Rights
      Reserved.</p>

</div> -->
