<div class="d-flex justify-content-center loading" *ngIf="spinner">
    <div class="spinner-border" role="status" >
      <span class="sr-only loading_spinner"></span>
    </div>
  </div>
  
  <div id="contact" class="col-md-4 col-sm-12 mx-auto">
      <div class="contact-us">
        <div class="py-4 text-center">
          <div class="contact-us-title">Please provide us with a few details so that we can register you for the free bridge course.</div>
          <div class="text-center"><b>OR</b></div>
          You can contact us on <a href="tel:+91-8928382838">+91-8928382838</a> between 10AM to 10PM or <br>EMAIL US at <a href="mailto:admin@primeacademypune.com">admin@primeacademypune.com</a>
        </div>
        <form>
          <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="Your Name"
              #name
            />
          </div>
          <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="Your Email ID"
              #email
            />
          </div>
          <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="Your Mobile Number"
              #mob
            />
          </div>
          <div class="form-group">
            <input
              class="w-100 p-input"
              type="text"
              placeholder="Your/Your Child's year of 10th board exam"
              #boardexamyear
            />
          </div>

          <div class="pb-1" style="font-size: 14px; text-align: center;">We will be providing scholarships to economically weaker sections, hence we are asking for your category.</div>
          <div class="form-group">
            <select class="w-100 p-input" #category>
              <option selected>Select the category you belong to</option>
              <option value="General">General</option>
              <option value="OBC">OBC</option>
              <option value="GEN-EWS">GEN-EWS</option>
              <option value="OBC-NCL">OBC_NCL</option>
              <option value="SC">SC</option>
              <option value="ST">ST</option>
              <option value="Persons with Disabilities">PwD</option>
            </select>
          </div>
          <!-- <div class="form-group">
            <select class="w-100 p-input" #preparefor>
              <option selected>Want to Prepare for?</option>
              <option value="CET">CET</option>
              <option value="IIT JEE">IIT JEE</option>
              <option value="BITSAT">BITSAT</option>
              <option value="NEET">NEET</option>
            </select>
          </div> -->
          <!-- <div class="form-group">
            <select class="w-100 p-input" #preparefor>
              <option selected>Which Courses are you interested in?</option>
              <option value="CET">CET</option>
              <option value="IIT JEE">IIT JEE</option>
              <option value="BITSAT">BITSAT</option>
              <option value="NEET">NEET</option>
            </select>
          </div> -->
  
          <div class="form-group">
              <input
                class="w-100 p-input"
                type="text"
                placeholder="Brief Address"
                #briefaddress
              />
          </div>
          <div class="form-group">
            <textarea
              class="w-100 p-input"
              type="text"
              placeholder="Your Message for us.. (Optional)"
              #message
            ></textarea>
          </div>
          <button class="p-btn-red w-100 mb-5" (click)="send_form()">SUBMIT DETAILS</button>
        </form>
      </div>
    </div>